import { useGraphQL } from '@/data';
import { axiosClient } from '@/data/axios';
import { useCompanyId } from '@/providers/auth/useSessionStorageCompanyId';
import type { QueryLocationsReadArgs, QueryStoresReadArgs } from '@/types/schema';
import { domain } from '@/utils/config';
import { gql } from '@apollo/client';
import { useQuery } from '@tanstack/react-query';

export const useWidgetParams = () => {
	const { sessionCompanyId } = useCompanyId();
	const { data: widgetApp, isLoading: appIdLoading } = useQuery( {
		queryKey: [ 'widget-app', sessionCompanyId ],
		queryFn : async () => {
			const { data } = await axiosClient.get( `/api/user/widget/getApp?companyId=${sessionCompanyId}` );
			return data.app;
		},
		enabled: !!sessionCompanyId,
	} );
	
	const { data: stores, isLoading: storeLoading } = useGraphQL<QueryStoresReadArgs>( {
		query: gql`
			query StoresRead_611b($options: FilterOptions) {
				storesRead(options: $options) {
					items {
						id
					}
				}
			}
		`,
		queryKey : [ 'storesRead' ],
		variables: {
			options: {
				limit : 1,
				filter: { company: sessionCompanyId },
			},
		},
	} );
	const storeId = stores?.storesRead?.items[ 0 ]?.id;
	const storeUrl = storeId ? `${domain}/p/${storeId}/store` : '';
	
	const { data: paymentLocations, isLoading: paymentLoading } = useGraphQL<QueryLocationsReadArgs>( {
		query: gql`
			query LocationsRead_eaa0($options: FilterOptions) {
				locationsRead(options: $options) {
					items {
						id
					}
				}
			}
		`,
		queryKey : [ 'locationsRead' ],
		variables: {
			options: {
				limit : 1,
				filter: { gateway: { active: true } },
			},
		},
	} );
	
	const paymentLocationId = paymentLocations?.locationsRead?.items[ 0 ]?.id;
	const paymentUrl = paymentLocationId ? `${domain}/p/${paymentLocationId}/payment` : '';
	
	return {
		isLoading: appIdLoading || storeLoading || paymentLoading,
		appId    : widgetApp?.id as string,
		storeUrl,
		paymentUrl,
	};
};
