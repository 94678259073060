import AsyncLoadingButton from '@/components/form/asyncLoading/asyncLoadingButton';
import PageLinkComponent from '@/components/page/linkComponent';
import { axiosClient } from '@/data/axios';
import { mutateGraphQL } from '@/data/apollo';
import { CompanyWrite } from '@/data/company.graphql';
import { useWidgetParams } from '@/hooks/useWidgetParams';
import usePermissions from '@/providers/auth/usePermissions';
import { useCompanyId } from '@/providers/auth/useSessionStorageCompanyId';
import useUserInfo from '@/providers/auth/useUserInfo';
import { useModal } from '@/providers/modal';
import { ResponsiveModalContainer } from '@/providers/modal/responsiveModal';
import type { MutationCompanyWriteArgs } from '@/types/schema';
import { isSuperAdmin } from '@/utils/isSuperAdmin';
import { generateScript } from '@/utils/widget-setting';
import {
	Description as DescriptionIcon,
	LocalGroceryStore as LocalGroceryStoreIcon,
	Paid as PaidIcon,
} from '@mui/icons-material';
import {
	Box,
	Checkbox,
	Divider,
	ListItemButton,
	ListItemText,
	MenuItem,
	Stack,
	TextField,
	Tooltip,
	Typography,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import React, { useMemo, useState } from 'react';
import { CodeBlock, dracula } from 'react-code-blocks';
import { useTranslation } from 'react-i18next';
import { WebsiteDialog } from './widgetDialog';

const validateWebsite = ( website: string ) => {
	let error = '';
	if ( !website ) {
		error = 'Website is required';
	} else if ( !website.startsWith( 'https://' ) ) {
		error = 'Website Url must start with https:// and end with a proper domain';
	}
	return error;
	
};

type ConfigKey = 'store' | 'invoice' | 'payment' | 'checkout';

function WidgetBannerModal() {
	const queryClient = useQueryClient();
	const { enqueueSnackbar } = useSnackbar();
	const { showModal } = useModal();
	const { t } = useTranslation();
	const { sessionCompanyId } = useCompanyId();
	const { appId, storeUrl, paymentUrl, isLoading } = useWidgetParams();
	const { staff, user } = useUserInfo();
	const isStaffOwner = usePermissions( [ 'OWNER' ] );
	const isOwner = staff ? isStaffOwner : true;
	const superAdmin = isSuperAdmin( user );
	
	const [ setting, setSetting ] = useState<{ [key in ConfigKey]: boolean }>( {
		store   : true,
		invoice : true,
		payment : true,
		checkout: false,
	} );
	const [ website, setWebstie ] = useState( '' );
	const [ websiteError, setWebsiteError ] = useState( '' );
	
	const script = useMemo( () => {
		if ( isLoading ) return '';
		return generateScript( {
			appId,
			invoice   : setting.invoice,
			storeUrl  : setting.store ? storeUrl : '',
			paymentUrl: setting.payment ? paymentUrl : '',
			checkout  : setting.checkout,
		} );
	}, [ setting, isLoading ] );
	
	const toggleSetting = ( key: ConfigKey ) => {
		setSetting( ( prev ) => ( { ...prev, [ key ]: !prev[ key ] } ) );
	};
	const addWebsiteEligible = staff && staff?.company && !staff?.company?.website && isOwner;
	
	return (
		<ResponsiveModalContainer
			title='Create your widget'
			secondaryTitle='What buttons do you need on your website?'
			saveButtonText='Copy Code'
			saveButtonProps={{ disabled: !appId }}
			actionItems={staff?.company?.website ? [ {
				buttonProps: { color: 'primary', sx: { textTransform: 'lowercase' } },
				name       : `Preview on ${staff?.company?.website}`,
				onClick    : () => showModal( WebsiteDialog, { variant: 'fullPageDialog' }, undefined ),
			} ] : undefined}
			onSave={async () => {
				await navigator.clipboard.writeText( script );
				enqueueSnackbar( 'Link copied to clipboard.', { variant: 'success' } );
			}}>
			<Stack
				spacing={1}
				sx={{
					'.MuiBox-root': {
						'width'              : 'max-content',
						'.MuiButtonBase-root': {
							fontSize    : '13px',
							bgcolor     : 'background.default',
							borderRadius: 50,
							border      : 1,
							mb          : 1,
							borderColor : 'background.default',
							boxShadow   : 'rgba(35, 47, 53, 0.09) 0px 2px 8px 0px',
							color       : '#fff',
						},
					},
				}}>
				<ListItemButton onClick={() => toggleSetting( 'invoice' )}>
					<ListItemText
						primary={(
							<Box>
								<MenuItem>
									<DescriptionIcon sx={{ width: 17, height: 17, mr: 1, color: 'text.primary' }}/>
									<Typography sx={{ color: 'text.primary' }}>View My Invoices</Typography>
								</MenuItem>
							</Box>
						)}
						secondary='Integrate a branded portal on your website where clients can conveniently view, manage, and pay their invoices, as well as access their Clover orders. This portal will allow clients to review both past and upcoming invoices, make payments directly, and generate their own statements for easier financial management.'
					/>
					<Checkbox checked={setting.invoice}/>
				</ListItemButton>
				<Tooltip title={!storeUrl ? 'You need to create your store link in the online store page.' : null}>
					<Stack>
						<ListItemButton
							disabled={!storeUrl}
							onClick={() => toggleSetting( 'store' )}>
							<ListItemText
								primary={(
									<Box>
										<MenuItem>
											<LocalGroceryStoreIcon sx={{ width: 17, height: 17, mr: 1, color: 'text.primary' }}/>
											<Typography sx={{ color: 'text.primary' }}>Order Now</Typography>
										</MenuItem>
									</Box>
								)}
								secondary='Incorporate an online store (or integrate your existing Invoiss online store) into your website domain. This will enable clients to place orders directly within your website, enhancing the user experience in the purchasing process.'
							/>
							<Checkbox checked={storeUrl ? setting.store : false}/>
						</ListItemButton>
					</Stack>
				</Tooltip>
				<Tooltip title={!paymentUrl ? 'You need to add a payment gateway to your account.' : null}>
					<Stack>
						<ListItemButton
							disabled={!paymentUrl}
							onClick={() => toggleSetting( 'payment' )}>
							<ListItemText
								primary={(
									<Box>
										<MenuItem>
											<PaidIcon sx={{ width: 17, height: 17, mr: 1, color: 'text.primary' }}/>
											<Typography sx={{ color: 'text.primary' }}>Pay</Typography>
										</MenuItem>
									</Box>
								)}
								secondary='Add a payment link to your website to allow clients to send payments of any amount directly to you without needing an invoice. This feature simplifies transactions, making it easier for clients to pay for services or products on-the-go.'
							/>
							<Checkbox checked={paymentUrl ? setting.payment : false}/>
						</ListItemButton>
					</Stack>
				</Tooltip>
				{superAdmin && (
					<Stack>
						<ListItemButton
							onClick={() => toggleSetting( 'checkout' )}>
							<ListItemText
								primary={(
									<Box>
										<MenuItem>
											<PaidIcon sx={{ width: 17, height: 17, mr: 1, color: 'text.primary' }}/>
											<Typography sx={{ color: 'text.primary' }}>Checkout</Typography>
										</MenuItem>
									</Box>
								)}
								secondary='Description of the checkout feature.'
							/>
							<Checkbox checked={setting.checkout}/>
						</ListItemButton>
					</Stack>
				)}
				{appId && <Divider sx={{ my: 1 }}/>}
				<Box sx={{ textAlign: 'center', width: '100% !important' }}>
					<Typography
						component={PageLinkComponent}
						color='primary'
						target='_blank'
						href='https://www.youtube.com/watch?v=PB9au9DY9Mk'>
						Watch Demo
					</Typography>
				</Box>
				{!appId ? (
					<Stack direction='row' alignItems='center' justifyContent='center' spacing={1}>
						{addWebsiteEligible && (
							<TextField
								sx={{ width: '55%' }}
								label={t( 'settings:company-website' )}
								value={website}
								helperText={websiteError}
								onChange={( e ) => {
									setWebstie( e.target.value );
									const error = validateWebsite( e.target.value );
									setWebsiteError( error );
								}}
							/>
						)}
						<AsyncLoadingButton
							size='medium'
							variant='contained'
							loading={isLoading}
							disabled={addWebsiteEligible && ( !website || Boolean( websiteError ) || website.length < 9 )}
							sx={{
								width    : 'fit-content',
								px       : 3,
								alignSelf: 'center',
							}}
							onClick={async () => {
								if ( addWebsiteEligible && website && !websiteError ) {
									await mutateGraphQL<MutationCompanyWriteArgs>( {
										mutation : CompanyWrite,
										variables: { input: { website } },
										context  : { headers: { company: staff?.company.id } },
									} );
									await queryClient.invalidateQueries( [ 'user' ] );
								}
								await axiosClient.post( '/api/user/widget/createApp', { companyId: sessionCompanyId } );
								await queryClient.invalidateQueries( [ 'widget-app' ] );
								enqueueSnackbar( 'Widget App created successfully', { variant: 'success' } );
							}}>
							View my widget
						</AsyncLoadingButton>
					</Stack>
				) : (
					<Box>
						<Typography variant='h1' sx={{ textAlign: 'center' }}>2 Simple steps</Typography>
						<Stack direction='row' spacing={2} sx={{ alignItems: 'center', pb: 2 }}>
							<Box
								className='center'
								sx={{
									bgcolor     : '#ffffff',
									borderRadius: '100%',
									height      : 30,
									width       : '30px !important',
								}}>
								<Typography color='#000000' variant='h5' sx={{ p: 2 }}>1</Typography>
							</Box>
							<Typography variant='h4'>
								Copy the code
							</Typography>
						</Stack>
						<CodeBlock
							showLineNumbers
							codeContainerStyle={{ width: '100%' }}
							theme={dracula}
							text={script}
							language='html'
						/>
						<Stack direction='row' spacing={2} sx={{ mt: 5, alignItems: 'center', pb: 2 }}>
							<Box
								className='center'
								sx={{
									bgcolor     : '#ffffff',
									borderRadius: '100%',
									height      : 30,
									width       : '30px !important',
								}}>
								<Typography color='#000000' variant='h5' sx={{ p: 2 }}>2</Typography>
							</Box>
							<Typography variant='h4'>
								Paste to your website footer
							</Typography>
						</Stack>
						<img
							alt='Paste Code Here'
							src='/images/pastecode.png'
							style={{ borderRadius: 2 }}
						/>
						<Typography variant='h3' sx={{ textAlign: 'center' }}>All Done</Typography>
					</Box>
				)}
			</Stack>
		</ResponsiveModalContainer>
	);
}

export default WidgetBannerModal;
