import { isProduction } from './config';

export const generateScript = ( {
	appId,
	invoice = true,
	storeUrl,
	paymentUrl,
	checkout = false,
}: { appId: string, invoice?: boolean, storeUrl?: string, paymentUrl?: string, checkout?:boolean } ) => `<script id='widget-config'>
\t\twindow.invoissConfig = {
\t\t\tappId: '${appId}',${invoice ? `\n\t\t\tinvoice: ${invoice},` : ''}${storeUrl
	? `\n\t\t\tstoreUrl: '${storeUrl}',`
	: ''}${paymentUrl
	? `\n\t\t\tpaymentUrl: '${paymentUrl}',`
	: ''}${checkout
	? '\n\t\t\tcheckout: {\n\t\t\t\tpath: \'your_check_out_path(e.g. /checkout)\'\n\t\t\t},'
	: ''}
\t\t};
</script>
<script type='module' src='https://${!isProduction && 'stage.' || ''}widget.invoiss.com/invoiss-widget.js'></script>`;
